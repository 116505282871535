import React from 'react';

import {Link} from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {Home} from '@material-ui/icons/';

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  brand: {
    flexGrow: 1
  }
}));

export default function Navbar(props){
  const classes = useStyles();
  return (
    <AppBar position="static">
      <ToolBar variant="dense">
        <Typography variant="h5" className={classes.brand}>
          <Link to="/">
            aritan.space
          </Link>
        </Typography>
        <Link to="/">
          <IconButton edge="start" className={classes.homeButton} color="inherit" aria-label="home">
            <Home />
          </IconButton>
        </Link>
      </ToolBar>
    </AppBar>
  );
};