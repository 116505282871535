import React from 'react';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Container from '@material-ui/core/Container';
import {createMuiTheme} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/styles';
import {amber} from '@material-ui/core/colors';
import {makeStyles} from '@material-ui/core/styles';

import Navbar from './components/navbar';
import Photos from './pages/photos';

import pics from "./data/pics.json";
import './App.css';

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#222222",
      contrastText: "#fff"
    },
    secondary: {
      main: amber[500]
    }
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: "15px",
    marginBottom: "15px"
  },
  homeButton: props => ({
    marginLeft: theme.spacing(1),
    color: props.palette.secondary.main
  })
}));

function App() {
  const classes = useStyles(theme);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar />
        <Container className={classes.root}>
          <Switch>
            <Route path="/" exact render={(props) => <Photos pics={pics} {...props} />} />
            <Route render={(props) => <h2>No match</h2>} />
          </Switch>
        </Container>
      </Router>
    </ThemeProvider>
  );
};

export default App;
