import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import PhotoModal from '../components/photoModal';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {

  }
}));

export default function Photos(props){
  const classes = useStyles();
  const theme = useTheme();
  const height = useMediaQuery(theme.breakpoints.up("sm")) ? 300 : 150;
  const [open, setOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);

  const handleClickOpen = (index) => {
    setOpen(true);
    setPhotoIndex(index)
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <GridList cols={5} className={classes.gridList} cellHeight={height}>
        {
          props.pics.map((tile, index) => (
            <GridListTile key={tile.src} cols={tile.cols || 2}>
               <img className="galleryImage" src={tile.src} alt={tile.title} key={index} onClick={() => handleClickOpen(index)} />
            </GridListTile>
          ))
        }
      </GridList>
      <PhotoModal
        open={open}
        handleClose={handleClose}
        src={props.pics[photoIndex].src}
        title={props.pics[photoIndex].title}
        description={props.pics[photoIndex].description}
      />
    </div>
  );
};